<template>
  <div class="maincon">
    <div class="bmap-page-container">
      <el-bmap
        vid="bmapDemo"
        :bmap-manager="bmapManager"
        :zoom="zoom"
        :center="center"
        :events="events"
        class="bmap-demo"
      >
        <weixing-plugin :map="map"></weixing-plugin>
        <el-bmap-polygon
          :v-if="polygons.length"
          v-for="(polygon, index) in polygons"
          :visible="visible"
          :key="polygon.key"
          :vid="index"
          :ref="`polygon_${index}`"
          fillColor="#dedede"
          strokeStyle="dashed"
          :path="polygon.path"
          :enable-editing="polygon.enableEditing"
          :events="polygon.events"
        ></el-bmap-polygon>

        <el-bmap-label
          v-for="item in pointsList"
          :position="[item.lng_lat.lng, item.lng_lat.lat]"
          :visible="item.visible"
          :is-custom="true"
        >
          <div class="labelbox" style="color: #fff; font-size: 20px">
            <div class="labelcontent">
              <img class="iconimg" :src="item.imgurl" />
              <div class="labeltext">
                {{ item.name || "空" }}
              </div>
            </div>
            <span class="labeldown" style="color: #000"></span>
          </div>
        </el-bmap-label>
      </el-bmap>
    </div>
  </div>
</template>

<script>
import weixingPlugin from "@/components/bmap/weixingPlugin.vue";
import { polygonData } from "@/api/cityresearch";
import VueBMap from "vue-bmap-gl";
let bmapManager = new VueBMap.BMapManager();

export default {
  props: {
    cname: String,
  },
  components: {
    weixingPlugin,
  },
  computed: {
    currentArea: {
      get() {
        return this.$store.state.currentArea;
      },
    },
  },

  watch: {
    cname(v) {
      let myGeo = new BMapGL.Geocoder();
      var that = this;
      myGeo.getPoint(
        v + "人民政府",
        function (point) {
          if (point) {
            that.map.centerAndZoom(point);
          } else {
            alert("您选择的地址没有解析到结果！");
          }
        },
        v + "市"
      );
      // alert(v)
      this.setAreaLine(v);
    },
  },

  data() {
    return {
      visible: true,
      polygons: [],
      blockoverlay: {},
      area: "",
      pointsList: [
        {
          id: 1,
          name: "这是标题1",
          lng_lat: {
            lat: "1",
            lng: "1",
          },
          imgurl: "",
        },
      ],
      map: {},
      bmapManager,
      geodata: "",
      events: {
        init: (o) => {
          this.map = o;
          let currentname = this.cname;
          let myGeo = new BMapGL.Geocoder();
          // 将地址解析结果显示在地图上，并调整地图视野
          let that = this;
          myGeo.getPoint(
            currentname + "市人民政府",
            function (point) {
              if (point) {
                o.centerAndZoom(point, 10);
                // o.addOverlay(new BMapGL.Marker(point, {title: ' '}))

                that.setAreaLine(currentname);
              } else {
                alert("您选择的地址没有解析到结果！");
              }
            },
            currentname + "市"
          );
        },
      },
      count: 1,
      center: [0, 0],
      zoom: 12,
    };
  },
  methods: {
      bd_convert_gd(coordinate) {
          var bd_lng = coordinate[0];
          var bd_lat = coordinate[1];
          var pi = 3.14159265358979324 * 3000.0 / 180.0;
          var x = bd_lng - 0.0065;
          var y = bd_lat - 0.006;
          var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * pi);
          var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * pi);
          var gd_lng = z * Math.cos(theta);
          var gd_lat = z * Math.sin(theta);
          return [gd_lng,gd_lat];
      },
      
    getPolygonData() {
      const params = {
        city_plate_id: this.currentArea,
        type: 10,
      };

      polygonData(params).then((res) => {
        const polygondata = res.data;
        polygondata.map((item) => {
          this.handlerData(item.set);
        });
      });
    },

    //获取点数据
    getPointData() {
      const params = {
        city_plate_id: this.city_plate_id,
        type: this.cate,
      };

      if (this.city_plate_id) {
        pointData(params).then((res) => {
          // console.log('===', res)
          this.pointsList = this.handleImg(res.data);
        });
      }
    },

    // 处理数据
    handlerData(data) {
      const dataArr = JSON.parse(data); // 获取地图数据数组
      dataArr.map((obj) => {
        this.drawPloygonByBD(obj);
      });
    },

    // 获取手动画 数据库api数据 画多变形
    drawPloygonByBD(obj) {
      // this.map.clearOverlays()// 添加覆盖物

      var ply = new BMapGL.Polygon(obj.multipoint, {
        strokeWeight: 2,
        // strokeColor: obj.strokecolor
        strokeStyle: "dashed",
        fillColor: "#dedede",
        strokeColor: "#000000",
      }); // 建立多边形覆盖物
      // console.log('===ply==', ply)
      // this.overlaycompleteByBD(ply)
      this.blockoverlay = ply;

      this.map.addOverlay(ply); // 添加覆盖物
      this.map.setViewport(ply.getPath()); // 调整视野
    },

    toChangeLocation() {},

    changeCenter() {
      let o = this.bmapManager.getMap();
      console.log(o);
    },
    setAreaLine(cname) {
      if (cname) {
        var bdary = new BMapGL.Boundary();

        var that = this;
        bdary.get(cname, function (rs) {
          //这里是用户自己的函数。

          //这里是用户自己的函数。
          that.map.removeOverlay(that.blockoverlay);
          that.polygons = [];

          if (rs.boundaries.length === 0) {
            //自定义区域画多变形
            that.getPolygonData();
          } else {
            const newbounderies = rs.boundaries.map((item) => {
              let single = item.split(";");
              let newItem = single.map((v) => {
                return v.split(",");
              });
              const object = {
                path: newItem,
                key: cname,
              };
              return object;
            });
            that.polygons = newbounderies;
          }
          // that.map.setViewport(newbounderies) // 调整视野
        });
      }
    },
  },

 
};
</script> 
<style lang="scss">
.maincon {
  position: relative;
  // height: calc(100vh - 220px);
  min-height: 570px;
}

.contrl {
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  background: #fff;
  height: calc(100vh - 220px);
  z-index: 9999;
  // min-height:460px;
}

.bmap-demo {
  height: calc(100vh - 240px);
  min-height: 570px;
}
</style>