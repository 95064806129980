<template>
  <div class="cityLocation">
    <breadcrumb  name="城市区位"></breadcrumb>
    <city-list  @selectedCity="selectedCid"></city-list>
    <div class="areaMap">
      <!-- <city-layer :cname="cname" ></city-layer> -->
      <leaflet-city-layer :cname="cname"  ></leaflet-city-layer>
    </div>
    <div class="swipercontainer">
        <down :imglist="imglist"></down>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../components/common/Breadcrumb.vue'
import CityList from '../components/cityreserch/CityList.vue'

import CityLayer from '../components/bmap/cityLayer.vue'
import LeafletCityLayer from '../components/leaflet/leafletCityLayer.vue'
import Down from '@/components/swiper/down.vue'

import {cityLocationImg ,polygonData} from "../api/cityresearch"
// import VueBMap from 'vue-mapvgl'
export default {
  components: { Breadcrumb, CityList, LeafletCityLayer, CityLayer, Down, },
  data(){
    return {
      imglist:[],
      params:{},
      polygondata:[]
      
    }
  },
  computed:{
    cname:{
      get(){
        let a =  this.$store.state.cname 
        // console.log('a',  this.$store.state.cname,'aend')
        // let a = "苏州市"
        // console.log('a',a,'b')
        return a
      },
      set(v){
       this.$store.commit('changeCname', v)
      }
    },
    //当前选择的id
    currentArea:{
      get(){
        return this.$store.state.currentArea
      }
    }
  },
  watch:{
    currentArea(newv){
      
      this.cname = this.$store.state.cname
      
      this.getImgList()
      this.getPolygonData()

    }
  },
  mounted(){
      this.cname = this.$store.state.cname
      this.getImgList()
      
      this.getPolygonData()
  },
  methods:{
    getPolygonData() {
      const params = {
        city_plate_id: this.currentArea,
        type: 10
      }

      polygonData(params).then(res => {
        const polygondata = res.data
        this.polygondata = polygondata
      })
    },


    selectedCid(cid){
      this.$store.state.currentCity.filter(item=>{
        if(item.id === cid){
          this.$store.commit('changeCname',item.label)
        }
      })
    },
    getImgList(){
      cityLocationImg({city_plate_id:this.currentArea}).then(res=>{
        console.log('imglist',res.data)
        this.imglist = res.data
      })
      // this.imglist = [];

    }

  }
}
</script>
<style lang="scss">
  .cityLocation{
    width:100%;
    overflow: hidden;
    color:#333;
  }
  .areaMap{
    width:98%;
    margin-left:1%;
    height:calc(100vh - 244px);
    min-height:560px;
    background:#ddd;
    overflow: hidden;
  }
  .swipercontainer{
    position: relative;
    bottom: 0;
    right:0;
    left:0;
    top:-100%;
    z-index:10;
  }
</style>